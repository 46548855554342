(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.controller:DashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl(LoggedUser) {
    var vm = this;

    vm.transactionLimit = 5;

    /**
     * Return the logged company's vat number
     *
     * @returns {string}
     */
    vm.getActiveCompanyVatNumber = function getActiveCompanyVatNumber() {
      return LoggedUser.getCompany().vatNumber;
    };
  }
}());
